import {useEffect, useState} from "react";
import axiosInstance from "./api/axiosInstance";
import TopUpNotify from "./components/TopUpNotify";
import BottomPage from "./components/BottomPage";
import BottomPageCaller from "./components/BottomPageCaller";
import KeyCard from "./components/KeyCard";
import SelectedKeyData from "./BottomPages/SelectedKeyData";
import ShowBottomPage from "./BottomPages/controller/BottomPageController";
import WhatIsLabel from "./Fullscreen Messages/WhatIsLabel";
import SetLabelWindow from "./Fullscreen Messages/SetLabelWindow";
import PaymentWindow from "./Fullscreen Messages/PaymentWindow";

export default function Personal() {

    const [keyList, setKeyList] = useState([]);
    const [selectedKeyData, setSelectedKeyData] = useState(null);
    const [showKeyDataPage, setShowKeyDataPage] = useState(false);

    // MODALS
    const [whatIsLabelVisible, setWhatIsLabelVisble] = useState(false);
    const [setLabelVisible, setSetLabelVisible] = useState(false);
    const [paymentVisible, setPaymentVisible] = useState(false);

    const ResetView = () => {
        window.location.reload()
    }

    useEffect(() => {
        axiosInstance.get('/keys')
            .then((response) => {
                setKeyList(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
        const interval = setInterval(() => {
            axiosInstance.get('/keys')
                .then((response) => {
                    setKeyList(response.data);
                })
                .catch((error) => {
                    console.log(error);
                })
        }, 3000);
        return () => clearInterval(interval);
    }, [])

    return (
        <>
            {/*END NOTIFY UI*/}
            <div className={'h-screen w-screen border-0 border-green-500 p-10'} id={'overlay-canvas'}>
                <div className={'flex justify-between items-center text-3xl'}>
                    <h2>Личный кабинет</h2>
                </div>

                {/*KEYS*/}
                <div className={'mt-5 flex flex-col gap-5'}>
                    {
                        keyList.map((item, index) =>
                            <KeyCard keyData={item} key={index} onClickAct={() => {
                                setSelectedKeyData(item);
                                setShowKeyDataPage(true);
                            }}/>
                        )
                    }
                </div>


            </div>

            {
                (showKeyDataPage && selectedKeyData) && (
                    <SelectedKeyData setKeyList={setKeyList} keyData={selectedKeyData} setShow={setShowKeyDataPage}
                                     setShowPayment={setPaymentVisible} setSetLabelShow={setSetLabelVisible}
                                     setWhatIsLabelShow={setWhatIsLabelVisble}/>
                )
            }

            <WhatIsLabel isShow={whatIsLabelVisible} setShow={setWhatIsLabelVisble}/>
            <SetLabelWindow isShow={setLabelVisible} setShow={setSetLabelVisible} reloadView={ResetView}
                            keyData={selectedKeyData}/>
            <PaymentWindow isShow={paymentVisible} setShow={setPaymentVisible}/>
        </>

    )

}