import FullscreenMessage from "../components/FullscreenMessage";
import PrimaryButton from "../components/PrimaryButton";
import beep from '../media/BeepTransparent.svg'

export default function PaymentWindow({isShow, setShow}) {
    return (
        <FullscreenMessage isShow={isShow}>
            <div className={'h-screen text-center gap-5 flex flex-col justify-center items-center'}>
                <img src={beep} className={'h-[200px]'} />
                <p className={'text-5xl'}>Оплата ключа</p>
                <p>Мы выставили вам счет через Beep. Загляните в чат, как только оплата пройдет мы начислим вам 30 дней подписки.</p>

                <PrimaryButton onClick={() => setShow(false)}>Уже бегу</PrimaryButton>
            </div>
        </FullscreenMessage>
    )
}