import axios from "axios";

const axiosInstance = axios.create({
    baseURL: 'https://vpn.back.worldz.tech/api/management/'
})

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers['BEEP-ATOKEN'] = token
    }
    return config;
}, (error) => Promise.reject(error))

export default axiosInstance;