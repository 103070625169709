import FullscreenMessage from "../components/FullscreenMessage";
import PrimaryButton from "../components/PrimaryButton";

export default function WhatIsLabel({isShow, setShow}) {
    return (
        <FullscreenMessage isShow={isShow}>
            <div className={'h-screen text-center gap-5 flex flex-col justify-center items-center'}>
                <i className='bx bxs-purchase-tag-alt bx-tada text-9xl'></i>
                <p className={'text-5xl'}>Ярлыки</p>
                <p>Вообще, мы не любители ярлыков, если вы понимаете, о чём мы.</p>
                <p>Но ярлыки — это очень удобная штука. Иногда может понадобиться создать несколько ключей: один для своего телефона, другой для компьютера, третий для любимой бабули.</p>
                <p>И вот здесь ярлыки помогают не запутаться во всём этом. Добавьте ярлык для каждого ключа, чтобы избежать путаницы. Ярлыки ни на что не влияют — они лишь помогают вам не перепутать ключи.</p>

                <PrimaryButton onClick={() => setShow(false)}>Полезно, спасибо</PrimaryButton>
            </div>
        </FullscreenMessage>
    )
}