import FullscreenMessage from "../components/FullscreenMessage";
import PrimaryButton from "../components/PrimaryButton";
import DangerButton from "../components/DangerButton";

export default function SetupApp({isShow, setShow, onClick}) {
    return (
        <FullscreenMessage isShow={isShow}>
            <div className={'h-screen text-center gap-5 flex flex-col justify-center items-center'}>
                <i className='bx bxs-download bx-tada text-9xl'></i>
                <p className={'text-5xl'}>Установите приложение</p>
                <p>Вы можете мгновенно установить Worldz VPN Personal на свой телефон. Установим?</p>


                <PrimaryButton onClick={() => onClick()}>Давайте</PrimaryButton>
                <DangerButton onClick={() => setShow(false)}>Позже</DangerButton>
            </div>
        </FullscreenMessage>
    )
}