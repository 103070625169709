import FullscreenMessage from "../components/FullscreenMessage";
import PrimaryButton from "../components/PrimaryButton";
import DangerButton from "../components/DangerButton";
import instructions from "../media/HowInstallOnIos.mp4";

export default function HowToInstallOnIos({isShow, setShow}) {
    return (
        <FullscreenMessage isShow={isShow}>
            <div className={'h-screen text-center gap-5 flex flex-col justify-center items-center'}>
                <video src={instructions} autoPlay={true} controls={false} className={'h-1/3'}/>
                <p className={'text-5xl'}>Как установить на iOS</p>
                <p>Очень просто! Смотрите объяснение выше.</p>

                <PrimaryButton onClick={() => setShow(false)}>Понятно!</PrimaryButton>
            </div>
        </FullscreenMessage>
    )
}