import {useEffect} from "react";

export default function FullscreenMessage({children, isShow}) {

    useEffect(() => {
        if (isShow) {
            document.body.style.overflow = "hidden"; // Отключаем прокрутку
        } else {
            document.body.style.overflow = "auto"; // Включаем прокрутку обратно
        }
    }, [isShow]);

    return (
        <>
            {
                isShow ? (
                    <div className={'top-0 start-0 z-50 fixed w-screen h-screen p-10 bg-black text-white'}>
                        {children}
                    </div>
                ) : (
                    <></>
                )
            }
        </>
    )
}