export default function KeyCard({keyData, onClickAct}) {
    return (
        <div className={'p-5 bg-slate-700 flex justify-between rounded-xl items-center'} onClick={onClickAct}>
            <div>
                {
                    keyData.label ? (
                        <p className={'text-sm'}><i className='bx bxs-purchase-tag-alt'></i> {keyData.label}
                        </p>
                    ) : (
                        <p className={'text-sm'}>Ключ</p>
                    )
                }

                <p>{keyData.key_id}</p>
            </div>
            <div className={'text-end'}>
                {
                    keyData.subscription_days > 0 ? (
                        <>
                            <p className={'text-green-500 text-sm'}>Подписка действует еще</p>
                            <p>{keyData.subscription_days} <span className={'text-sm'}>дней</span></p>
                        </>
                    ) : (
                        <>
                            <p className={'text-red-500 text-sm'}>Подписка окончена</p>
                        </>
                    )
                }
            </div>
        </div>
    )
}