import BottomPage from "../components/BottomPage";
import {useEffect} from "react";
import axiosInstance from "../api/axiosInstance";

export default function SelectedKeyData({keyData, setShow, setWhatIsLabelShow, setSetLabelShow, setKeyList, setShowPayment}) {
    return (
        <BottomPage title={"Настройки ключа"}>
            <span className={'absolute top-0 end-0 m-10'} onClick={() => setShow(false)}>X</span>
            <div className={'flex justify-between items-center'}>
                <p>Ключ: {keyData.key_id}</p>
            </div>

            {
                    keyData.subscription_days > 0 ? (
                        <div className={'bg-green-500 p-5 rounded-xl mt-5 flex gap-5 items-center'}>
                            <box-icon name='check-circle' type='solid' color='#ffffff' ></box-icon>
                            <p className={'text-xl'}>Подписка активна.<br />Все хорошо.</p>
                        </div>

                    ) : (
                        <div className={'bg-red-500 p-5 rounded-xl mt-5 flex gap-5 items-center'}>
                            <box-icon name='block' color='#ffffff'></box-icon>
                            <p className={'text-xl'}>Подписка закончилась.<br/><u onClick={() => {
                                axiosInstance.post("send_invoice/", {
                                    key_id: keyData.key_id,
                                })
                                    .then((resp) => {
                                        setShowPayment(true)
                                    })
                                    .catch((error) => {
                                        alert('Упс! Произошла ошибка. Попробуйте позже или обратитесь в поддержку.')
                                    })
                            }}>Повторим?</u></p>
                        </div>
                    )
            }

            <div className={'mt-5'}>
                <div className={'flex gap-1 items-center text-xl'}>
                    <box-icon name='label' type='solid' color='#ffffff'></box-icon>
                    <p>Ярлык</p>
                    <p className={'text-slate-500 text-sm'} onClick={() => setWhatIsLabelShow(true)}><u>Что это?</u></p>
                </div>

                {
                    keyData.label ? (
                        <div>
                            <p>{keyData.label}</p>
                            <p className={'text-slate-500'}><u
                                onClick={() => setSetLabelShow(true)}>Хотите изменить?</u></p>
                        </div>
                    ) : (
                        <div>

                            <p className={'text-slate-500'}>На этом ключе нет ярлыка. <u onClick={() => setSetLabelShow(true)}>Установим?</u></p>

                        </div>
                    )
                }
            </div>

        </BottomPage>
    )
}